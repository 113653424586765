"use strict";
/**
 * @description 常亮
 * @author wangfupeng
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.urlRegex = exports.imgRegex = exports.EMPTY_FN = void 0;
function EMPTY_FN() { }
exports.EMPTY_FN = EMPTY_FN;
//用于校验图片链接是否符合规范
exports.imgRegex = /\.(gif|jpg|jpeg|png)$/i;
//用于校验是否为url格式字符串
exports.urlRegex = /^(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-.,@?^=%&amp;:/~+#]*[\w\-@?^=%&amp;/~+#])?/;
