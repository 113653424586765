"use strict";
/**
 * @description 工具函数集合
 * @author wangfupeng
 */
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.toArray = exports.deepClone = exports.isFunction = exports.debounce = exports.throttle = exports.arrForEach = exports.forEach = exports.replaceSpecialSymbol = exports.replaceHtmlSymbol = exports.getRandom = exports.UA = void 0;
var NavUA = /** @class */ (function () {
    function NavUA() {
        this._ua = navigator.userAgent;
        var math = this._ua.match(/(Edge?)\/(\d+)/);
        this.isOldEdge = math && math[1] == 'Edge' && parseInt(math[2]) < 19 ? true : false;
        this.isFirefox =
            /Firefox\/\d+/.test(this._ua) && !/Seamonkey\/\d+/.test(this._ua) ? true : false;
    }
    // 是否为 IE
    NavUA.prototype.isIE = function () {
        return 'ActiveXObject' in window;
    };
    // 是否为 webkit
    NavUA.prototype.isWebkit = function () {
        return /webkit/i.test(this._ua);
    };
    return NavUA;
}());
// 和 UA 相关的属性
exports.UA = new NavUA();
/**
 * 获取随机石
 * @param prefix 前缀
 */
function getRandom(prefix) {
    if (prefix === void 0) { prefix = ''; }
    return prefix + Math.random().toString().slice(2);
}
exports.getRandom = getRandom;
/**
 * 替换 html 特殊字符
 * @param html html 字符串
 */
function replaceHtmlSymbol(html) {
    return html
        .replace(/</gm, '&lt;')
        .replace(/>/gm, '&gt;')
        .replace(/"/gm, '&quot;')
        .replace(/(\r\n|\r|\n)/g, '<br/>');
}
exports.replaceHtmlSymbol = replaceHtmlSymbol;
function replaceSpecialSymbol(value) {
    return value
        .replace(/&lt;/gm, '<')
        .replace(/&gt;/gm, '>')
        .replace(/&quot;/gm, '"');
}
exports.replaceSpecialSymbol = replaceSpecialSymbol;
/**
 * 遍历对象或数组，执行回调函数
 * @param obj 对象或数组
 * @param fn 回调函数 (key, val) => {...}
 */
function forEach(obj, fn) {
    for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            var result = fn(key, obj[key]);
            if (result === false) {
                // 提前终止循环
                break;
            }
        }
    }
}
exports.forEach = forEach;
/**
 * 遍历类数组
 * @param fakeArr 类数组
 * @param fn 回调函数
 */
function arrForEach(fakeArr, fn) {
    var i, item, result;
    var length = fakeArr.length || 0;
    for (i = 0; i < length; i++) {
        item = fakeArr[i];
        result = fn.call(fakeArr, item, i);
        if (result === false) {
            break;
        }
    }
}
exports.arrForEach = arrForEach;
/**
 * 节流
 * @param fn 函数
 * @param interval 间隔时间，毫秒
 */
function throttle(fn, interval) {
    if (interval === void 0) { interval = 200; }
    var flag = false;
    return function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        if (!flag) {
            flag = true;
            setTimeout(function () {
                flag = false;
                fn.call.apply(fn, __spreadArrays([null], args)); // this 报语法错误，先用 null
            }, interval);
        }
    };
}
exports.throttle = throttle;
/**
 * 防抖
 * @param fn 函数
 * @param delay 间隔时间，毫秒
 */
function debounce(fn, delay) {
    if (delay === void 0) { delay = 200; }
    var lastFn = 0;
    return function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        if (lastFn) {
            window.clearTimeout(lastFn);
        }
        lastFn = window.setTimeout(function () {
            lastFn = 0;
            fn.call.apply(fn, __spreadArrays([null], args)); // this 报语法错误，先用 null
        }, delay);
    };
}
exports.debounce = debounce;
/**
 * isFunction 是否是函数
 * @param fn 函数
 */
function isFunction(fn) {
    return typeof fn === 'function';
}
exports.isFunction = isFunction;
/**
 * 引用与非引用值 深拷贝方法
 * @param data
 */
function deepClone(data) {
    if (typeof data !== 'object' || typeof data == 'function' || data === null) {
        return data;
    }
    var item;
    if (Array.isArray(data)) {
        item = [];
    }
    if (!Array.isArray(data)) {
        item = {};
    }
    for (var i in data) {
        if (Object.prototype.hasOwnProperty.call(data, i)) {
            item[i] = deepClone(data[i]);
        }
    }
    return item;
}
exports.deepClone = deepClone;
/**
 * 将可遍历的对象转换为数组
 * @param data 可遍历的对象
 */
function toArray(data) {
    return Array.prototype.slice.call(data);
}
exports.toArray = toArray;
