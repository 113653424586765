"use strict";
/**
 * @description 粘贴 text html
 * @author wangfupeng
 */
Object.defineProperty(exports, "__esModule", { value: true });
var paste_event_1 = require("../paste/paste-event");
var util_1 = require("../../utils/util");
var const_1 = require("../../utils/const");
/**
 * 格式化html
 * @param val 粘贴的html
 * @author liuwei
 */
function formatHtml(val) {
    var pasteText = val;
    // div 全部替换为 p 标签
    pasteText = pasteText.replace(/<div>/gim, '<p>').replace(/<\/div>/gim, '</p>');
    // 不允许空行，放在最后
    pasteText = pasteText.replace(/<p><\/p>/gim, '<p><br></p>');
    // 去除''
    return pasteText.trim();
}
/**
 * 格式化html
 * @param val 粘贴的html
 * @author liuwei
 */
function formatCode(val) {
    var pasteText = val.replace(/<br>|<br\/>/gm, '\n').replace(/<[^>]+>/gm, '');
    return pasteText;
}
/**
 * 粘贴文本和 html
 * @param editor 编辑器对象
 * @param pasteEvents 粘贴事件列表
 */
function pasteTextHtml(editor, pasteEvents) {
    function fn(e) {
        // 获取配置
        var config = editor.config;
        var pasteFilterStyle = config.pasteFilterStyle;
        var pasteIgnoreImg = config.pasteIgnoreImg;
        var pasteTextHandle = config.pasteTextHandle;
        // 获取粘贴的文字
        var pasteHtml = paste_event_1.getPasteHtml(e, pasteFilterStyle, pasteIgnoreImg);
        var pasteText = paste_event_1.getPasteText(e);
        pasteText = pasteText.replace(/\n/gm, '<br>');
        // 当前选区所在的 DOM 节点
        var $selectionElem = editor.selection.getSelectionContainerElem();
        if (!$selectionElem) {
            return;
        }
        var nodeName = $selectionElem === null || $selectionElem === void 0 ? void 0 : $selectionElem.getNodeName();
        var $topElem = $selectionElem === null || $selectionElem === void 0 ? void 0 : $selectionElem.getNodeTop(editor);
        // 当前节点顶级可能没有
        var topNodeName = '';
        if ($topElem.elems[0]) {
            topNodeName = $topElem === null || $topElem === void 0 ? void 0 : $topElem.getNodeName();
        }
        // code 中只能粘贴纯文本
        if (nodeName === 'CODE' || topNodeName === 'PRE') {
            if (pasteTextHandle && util_1.isFunction(pasteTextHandle)) {
                // 用户自定义过滤处理粘贴内容
                pasteText = '' + (pasteTextHandle(pasteText) || '');
            }
            editor.cmd.do('insertHTML', formatCode(pasteText));
            return;
        }
        // 如果复制进来的是url链接则插入时将它转为链接
        if (const_1.urlRegex.test(pasteText)) {
            return editor.cmd.do('insertHTML', "<a href=\"" + pasteText + "\" target=\"_blank\">" + pasteText + "</a>");
        }
        // table 中（td、th），待开发。。。
        if (!pasteHtml) {
            return;
        }
        try {
            // firefox 中，获取的 pasteHtml 可能是没有 <ul> 包裹的 <li>
            // 因此执行 insertHTML 会报错
            if (pasteTextHandle && util_1.isFunction(pasteTextHandle)) {
                // 用户自定义过滤处理粘贴内容
                pasteHtml = '' + (pasteTextHandle(pasteHtml) || ''); // html
            }
            editor.cmd.do('insertHTML', "" + formatHtml(pasteHtml));
        }
        catch (ex) {
            // 此时使用 pasteText 来兼容一下
            if (pasteTextHandle && util_1.isFunction(pasteTextHandle)) {
                // 用户自定义过滤处理粘贴内容
                pasteText = '' + (pasteTextHandle(pasteText) || '');
            }
            editor.cmd.do('insertHTML', "" + formatHtml(pasteText)); // text
        }
    }
    pasteEvents.push(fn);
}
exports.default = pasteTextHtml;
