"use strict";
/**
 * @description 检查选区是否在链接中，即菜单是否应该 active
 * @author wangfupeng
 */
Object.defineProperty(exports, "__esModule", { value: true });
function isActive(editor) {
    var $selectionELem = editor.selection.getSelectionContainerElem();
    if (!$selectionELem) {
        return false;
    }
    if ($selectionELem.getNodeName() === 'A') {
        return true;
    }
    else {
        return false;
    }
}
exports.default = isActive;
