"use strict";
/**
 * @description 事件配置
 * @author wangfupeng
 */
Object.defineProperty(exports, "__esModule", { value: true });
var const_1 = require("../utils/const");
exports.default = {
    onchangeTimeout: 200,
    onchange: const_1.EMPTY_FN,
    onfocus: const_1.EMPTY_FN,
    onblur: const_1.EMPTY_FN,
};
